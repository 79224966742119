import React, { Dispatch, useReducer } from 'react';
import { INotification } from '../config/interfaces';
import { reducer } from './NotificationReducer';

export interface IStateContext {
    isDropdownOpen: boolean;
    notifications: INotification[];
}

const initialState = {
    isDropdownOpen: false,
    notifications : [],
};

const StateContext = React.createContext<IStateContext | null>(null);
const DispatchContext = React.createContext<Dispatch<any> | undefined>(undefined);

const NotificationProvider = ({ children }: { children: any }) => {
    const [notifications, dispatch] = useReducer(reducer, initialState);

    return (
        <StateContext.Provider value={notifications}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};

export {
    DispatchContext,
    StateContext,
    NotificationProvider
};
