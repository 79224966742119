import React from 'react';
import { useAuth } from '../../Security/state/AuthContext';
import { IUser } from '../config/interfaces';

const defaultValue = {
    id       : null,
    birthDate: null,
    email    : null,
    firstName: null,
    lastName : null,
    office   : null,
};

const UserContext = React.createContext<IUser>(defaultValue);

const UserProvider: React.FC = (props) => {
    const { data: { user } } = useAuth();

    return <UserContext.Provider value={user} {...props} />
};

const useUser = () => {
    const context = React.useContext(UserContext);

    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }

    return context;
};

const useIsAuthenticated = (): boolean => {
    const context = React.useContext(UserContext);

    if (context === undefined) {
        throw new Error('useIsAuthenticated must be used within a UserProvider');
    }

    return Boolean(context && context.email !== null);
};

export { UserProvider, useUser, useIsAuthenticated }
