import apiClient, { IApiResponse } from '../lib/api-client';

export interface IConfigOption {
    name: string;
    content: { value: any };
}

interface IConfigApiResponse extends IApiResponse {
    payload: { config: IConfigOption };
}

interface IConfigsApiResponse extends IApiResponse {
    payload: { configs: IConfigOption[] };
}

function getAllConfig() {
    return apiClient('api/config/list').then((response: IConfigsApiResponse) => {
        if (response.status === 'OK') {
            return Promise.resolve(response.payload.configs);
        } else {
            return Promise.reject(new Error(response.status));
        }
    }).catch((error: Error) => {
        return Promise.reject(error);
    });
}

function getConfigOption(name: string) {
    return apiClient(`api/config/${name}`).then((response: IConfigApiResponse) => {
        if (response.status === 'OK') {
            return Promise.resolve(response.payload.config);
        } else {
            return Promise.reject(new Error(response.status));
        }
    }).catch((error: Error) => {
        return Promise.reject(error);
    });
}

export { getAllConfig, getConfigOption }
