import React, { useEffect, useState } from 'react';
import { getConfigOption, IConfigOption } from '../api/config-service';
import { API_CONFIGS } from '../config/constants';

interface IAppPublicContext {
    value: boolean;
}

const AppPublicContext = React.createContext<IAppPublicContext>({ value: false });

const AppPublicProvider: React.FC = (props) => {
    const [appPublic, setAppPublic] = useState({ value: false });

    useEffect(() => {
        getConfigOption(API_CONFIGS.PUBLIC_APP).then((payload: IConfigOption) => {
            setAppPublic(payload.content.value);
        });
    }, []);

    return <AppPublicContext.Provider value={appPublic} {...props} />;
};

const useAppPublic = () => {
    const context = React.useContext(AppPublicContext);

    if (context === undefined) {
        throw new Error('useAppPublic must be used within a AppPublicProvider');
    }

    return context;
};

export {
    AppPublicProvider,
    useAppPublic
}
