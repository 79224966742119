import each from 'lodash/each';
import { IAppConfig } from '../../Security/state/AuthContext';
import { getUser } from '../../User/api/user-service';
import { getAllConfig, IConfigOption } from '../api/config-service';

function getConfigs(configOptions: IConfigOption[]) {
    const appConfig = {} as { [key: string]: any };

    each(configOptions, (configOption: IConfigOption) => {
        appConfig[configOption.name] = configOption.content;
    });

    return appConfig;
}

async function bootstrapAppData() {
    try {
        const appConfig = await getAllConfig();
        const user = await getUser();

        return {
            appConfig: getConfigs(appConfig) as IAppConfig,
            user     : user.userData,
        };
    } catch (e) {
        return { appConfig: {} as IAppConfig, user: null }
    }
}

export { bootstrapAppData }
