import React from 'react';
import { NotificationProvider } from '../../Notification/state/NotificationContext';
import { AuthProvider } from '../../Security/state/AuthContext';
import { UserProvider } from '../../User/state/UserContext';
import { AppPublicProvider } from './AppPublicContext';

const AppProviders: React.FC = ({ children }) => {
    return (
        <AppPublicProvider>
            <AuthProvider>
                <UserProvider>
                    <NotificationProvider>
                        {children}
                    </NotificationProvider>
                </UserProvider>
            </AuthProvider>
        </AppPublicProvider>
    )
};

export default AppProviders;
