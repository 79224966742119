import clone from 'lodash/clone';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { IStateContext } from './NotificationContext';

interface IAction {
    type: string;
    payload: any;
}

const actionTypes = {
    'ADD_NOTIFICATION'           : 'addNotification',
    'MARK_AS_READ'               : 'markAsRead',
    'MARK_AS_UNREAD'             : 'markAsUnread',
    'REMOVE_NOTIFICATION'        : 'removeNotification',
    'SET_NOTIFICATIONS'          : 'setNotifications',
    'OPEN_NOTIFICATIONS_DROPDOWN': 'openNotificationsDropdown'
};

const reducer = (state: IStateContext, { type, payload }: IAction) => {
    let notificationsClone = null;
    let notification = null;
    let notificationIndex = null;

    switch (type) {
        case actionTypes.ADD_NOTIFICATION: {
            const notificationsClone = clone(state.notifications);

            notificationsClone.splice(0, 0, { ...payload.notification });

            return { ...state, notifications: notificationsClone };
        }
        case actionTypes.MARK_AS_READ:
            notificationsClone = clone(state.notifications);
            notification = find(notificationsClone, { id: payload.id });

            if (notification) {
                notificationIndex = findIndex(notificationsClone, { id: payload.id });

                notification.isRead = true;
                notificationsClone[notificationIndex] = notification;
            }

            return { ...state, notifications: notificationsClone };
        case actionTypes.MARK_AS_UNREAD:
            notificationsClone = clone(state.notifications);
            notification = find(notificationsClone, { id: payload.id });

            if (notification) {
                notificationIndex = findIndex(notificationsClone, { id: payload.id });

                notification.isRead = false;
                notificationsClone[notificationIndex] = notification;
            }

            return { ...state, notifications: notificationsClone };
        case actionTypes.REMOVE_NOTIFICATION:
            notificationsClone = clone(state.notifications);
            notificationIndex = findIndex(notificationsClone, { id: payload.id });
            notificationsClone.splice(notificationIndex, 1);

            return { ...state, notifications: notificationsClone };
        case actionTypes.SET_NOTIFICATIONS:
            return { ...state, notifications: payload.notifications };
        case actionTypes.OPEN_NOTIFICATIONS_DROPDOWN:
            return { ...state, isDropdownOpen: payload };
        default:
            return state;
    }
};

export {
    actionTypes,
    reducer
}
