import React, { useLayoutEffect, useState } from 'react';
import { useAsync } from 'react-async';
import FullScreenSpinner from '../../Core/components/FullScreenSpinner';
import { IMenu } from '../../Core/components/TopNav/Menu';
import { bootstrapAppData } from '../../Core/lib/bootstrap-data';
import { IUser } from '../../User/config/interfaces';
import * as AuthService from '../api/security-service';
import { IRegisterUser } from '../config/interfaces';

export interface IAppConfig {
    homepage: { value: number | null };
    menus: { value: IMenu[] };
}

interface IAuthContext {
    data: {
        appConfig: IAppConfig,
        user: IUser;
    },
    login: Function;
    logout: Function;
    register: Function;
}

const defaultValue = {
    data    : {
        appConfig: { homepage: { value: null }, menus: { value: [] } },
        user     : {
            id       : null,
            birthDate: null,
            email    : null,
            firstName: null,
            lastName : null,
            office   : null,
        },
    },
    login   : () => {
    },
    logout  : () => {
    },
    register: () => {
    },
};

const AuthContext = React.createContext<IAuthContext>(defaultValue);

const AuthProvider: React.FC = (props) => {
    const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);
    const {
              data = defaultValue.data,
              error,
              isRejected,
              isPending,
              isSettled,
              reload,
          } = useAsync({
        promiseFn: bootstrapAppData
    });

    useLayoutEffect(() => {
        if (isSettled) {
            setFirstAttemptFinished(true);
        }
    }, [isSettled]);

    if (!firstAttemptFinished) {
        if (isPending) {
            return <FullScreenSpinner />
        }

        if (isRejected) {
            return (
                <div style={{ color: 'red' }}>
                    <p>Uh oh... There is a problem. Try refreshing the app.</p>
                    <pre>{error && error.message}</pre>
                </div>
            );
        }
    }

    const login = (form: { email: string, password: string }) => {
        return AuthService.login(form).then(reload);
    };

    const logout = () => {
        return AuthService.logout().then(reload);
    };

    const register = (form: IRegisterUser) => {
        return AuthService.register(form);
    };

    return (
        <AuthContext.Provider value={{ data, login, logout, register }} {...props} />
    )
};

const useAuth = () => {
    const context = React.useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }

    return context;
};

export { AuthProvider, useAuth }
