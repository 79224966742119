import React from 'react';
import Spinner from 'reactstrap/lib/Spinner';

const FullScreenSpinner = () => {
    return (
        <div className="d-flex h-100 flex-column align-items-center justify-content-center"
             style={{ background: '#282c34' }}
        >
            <Spinner color="light" />
        </div>
    );
};

export default FullScreenSpinner;
