import isFunction from 'lodash/isFunction';

export interface IApiErrorMessage {
    context: string;
    message: string;
}

export interface IApiResponse {
    status: string;
    payload: object;
}

export interface IApiError {
    status: string;
    errors: IApiErrorMessage[]
}

function checkStatus(response: Response) {
    if (response.status === 200) {
        return response;
    }

    if (response.status === 204) {
        const res = {
            json: () => new Promise((resolve, reject) => resolve(response))
        };

        return Promise.reject(res);
    }

    return Promise.reject(response);
}

function parseResponse(response: Response) {
    //@TODO how can we fix this ?
    // @ts-ignore
    if (response && response.headers.get('Content-Type').indexOf('application/json') > -1) {
        return response.json();
    }

    // For other content types make sure we forward the response
    return response;
}

function client(endpoint: string, { body, ...customConfig } = {} as any) {
    const token = window.localStorage.getItem('auth_token');
    const headers = { 'content-type': 'application/json' } as { [key: string]: string };

    if (token) {
        headers['X-TOKEN'] = token;
    }

    const config = {
        method : body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    };

    if (body) {
        config.body = JSON.stringify(body);
    }

    return window
        .fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config)
        .then(checkStatus)
        .then(parseResponse)
        .catch(async response => {
            if (isFunction(response.json)) {
                const error = await response.json().then((json: IApiError) => json);

                return Promise.reject(error);
            }

            return await Promise.reject(response);
        });
}

export default client;
