import apiClient from '../../Core/lib/api-client';
import { getToken, logout } from '../../Security/api/security-service';

function getUser() {
    const token = getToken();

    if (token === null) {
        return Promise.resolve(null);
    }

    return apiClient('api/profile')
        .then(resp => Promise.resolve(resp.payload))
        .catch((error: Error) => {
            logout();

            return Promise.reject(error);
        });
}

function getUserPrivateDocuments() {
    const token = getToken();

    if (token === null) {
        return Promise.resolve(null);
    }

    return apiClient('api/user/files')
        .then(resp => Promise.resolve(resp.payload))
        .catch((error: Error) => {
            return Promise.reject(error);
        });
}

function downloadUserFile(fileId: number) {
    const token = getToken();

    if (token === null) {
        return Promise.resolve(null);
    }

    // make sure we provide token to authenticate get request
    return apiClient(`api/user/file/${fileId}/download`)
        .then(response => {
            return Promise.resolve(response.blob());
        })
        .catch((error: Error) => {
            return Promise.reject(error);
        });

}

export { getUser, getUserPrivateDocuments, downloadUserFile };
