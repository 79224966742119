import apiClient, { IApiResponse } from '../../Core/lib/api-client';
import { IRegisterUser } from '../config/interfaces';

const LOCAL_STORAGE_TOKEN_KEY = 'auth_token';

interface IAuthUser {
    token: string;
}

function handleUserResponse(userPayload: IAuthUser) {
    const { token } = userPayload;

    window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
}

function login(form: { email: string, password: string }) {
    return apiClient('api/accounts/login', { body: form })
        .then(({ payload }: { payload: IAuthUser }) => {
            handleUserResponse(payload);
        });
}

function logout() {
    window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);

    return Promise.resolve();
}

function register(form: IRegisterUser) {
    return apiClient('api/accounts/register', { body: form })
        .then((resp: IApiResponse) => Promise.resolve(resp))
        .catch((error: any) => Promise.reject(error));
}

function activateUser(data: { code: string }) {
    return apiClient('api/accounts/activate', { body: { code: data.code } })
        .then((data: any) => Promise.resolve(data))
        .catch((error: Error) => Promise.reject(error));
}

function getToken() {
    return window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
}

export { login, logout, register, getToken, activateUser }
