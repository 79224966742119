import React, { lazy, useEffect } from 'react';
import Spinner from 'reactstrap/lib/Spinner';
import NetworkDetector from './modules/Core/components/NetworkDetector';
import IconFonts from './modules/Core/lib/icon-fonts';
import { useIsAuthenticated } from './modules/User/state/UserContext';
import './scss/App.scss';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = lazy(loadAuthenticatedApp);
const UnauthenticatedApp = lazy(() => import('./UnauthenticatedApp'));

const App = ({ swRegistration }: { swRegistration: ServiceWorkerRegistration }) => {
    const isAuthenticated = useIsAuthenticated();

    // pre-load the authenticated side in the background
    // while the user is filling out the login form.
    useEffect(() => {
        IconFonts.init();
        loadAuthenticatedApp();
    }, []);

    return (
        <>
            <React.Suspense fallback={<Spinner />}>
                {isAuthenticated ? <AuthenticatedApp swRegistration={swRegistration} /> : <UnauthenticatedApp />}
            </React.Suspense>
        </>
    );
};

export default NetworkDetector(App);
