import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";

function IconFonts() {
    function init() {
        library.add(fas);
    }

    return {
        init
    }
}

export default IconFonts();
