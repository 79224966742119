import React, { useEffect, useState } from 'react';

function getDisplayName(WrappedComponent: any) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default (WrappedComponent: any) => {
    const NetworkDetectorHoc = (props: any) => {
        const [isDisconnected, setIsDisconnected] = useState(false);

        const handleConnectionChange = () => {
            const condition = navigator.onLine ? 'online' : 'offline';
            if (condition === 'online') {
                const webPing = setInterval(
                    () => {
                        fetch('https://www.google.com', {
                            mode: 'no-cors',
                        })
                            .then(() => {
                                setIsDisconnected(false);
                                clearInterval(webPing);
                            })
                            .catch(() => setIsDisconnected(true))
                    }, 2000);
                return;
            }

            return setIsDisconnected(true);
        };

        useEffect(() => {
            handleConnectionChange();
            window.addEventListener('online', handleConnectionChange);
            window.addEventListener('offline', handleConnectionChange);

            return () => {
                window.removeEventListener('online', handleConnectionChange);
                window.removeEventListener('offline', handleConnectionChange);
            }
        }, [isDisconnected]);

        return (
            <>
                {isDisconnected && (
                    <div className="alert-danger text-center py-2">Internet connection lost</div>
                )}
                <WrappedComponent {...props} />
            </>
        );
    };

    NetworkDetectorHoc.displayName = `NetworkDetectorHoc(${getDisplayName(WrappedComponent)})`;

    return NetworkDetectorHoc;
};

